import React, { useState, useEffect } from "react";
import { Breadcrumb, Statistic, Card, Row, Col } from "antd";
import {
  BarsOutlined,
  BankOutlined,
  UserOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Popup, MapContainer, TileLayer, Marker } from "react-leaflet";
//import { getCountFincaApi, getAllFincasApi } from "../../api/finca.api";
import { getCountUsersApi } from "../../api/user.api";
//import { getCountEncuestaApi } from "../../api/encuesta.api";

import { getAccessTokenApi } from "../../api/auth";
//import fincaSvg from "../../assets/img/svg/finca.svg";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import RightOutlined from "@ant-design/icons/RightOutlined";

import "react-leaflet-markercluster/dist/styles.min.css";

export default function Admin() {
  const token = getAccessTokenApi();
  const [countUserActive, setCountUserActive] = useState();
  const [countUserInactive, setCountUserInactive] = useState();
  const [countFincas, setCountFincas] = useState();
  const [countEncuestas, setCountEncuestas] = useState();
  const [fincas, setFincas] = useState([]);
  const position = [-1.298944947192408, -78.61818687151371];

  // const createClusterCustomIcon = function (cluster) {
  //   return L.divIcon({
  //     html: `<span>${cluster.getChildCount()}</span>`,
  //     className: "marker-cluster-custom",
  //     iconSize: L.point(60, 60, true),
  //   });
  // };

  useEffect(() => {
    // getCountFincaApi(token).then((response) => {
    //   setCountFincas(response.count);
    // });

    // getCountEncuestaApi(token).then((response) => {
    //   setCountEncuestas(response.count);
    // });

    getCountUsersApi(token, true).then((response) => {
      setCountUserActive(response.count);
    });

    getCountUsersApi(token, false).then((response) => {
      setCountUserInactive(response.count);
    });

    // getAllFincasApi(token).then((response) => {
    //   setFincas(response.fincas);
    // });
  }, []);

  // const iconFinca = new L.Icon({
  //   iconUrl: fincaSvg,
  //   iconRetinaUrl: fincaSvg,
  //   iconSize: [64, 64],
  //   iconAnchor: [32, 64],
  //   popupAnchor: [0, -46],
  //   shadowUrl: null,
  //   shadowSize: null,
  //   shadowAnchor: null,
  // });

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-statistic-demo-card">
        <Row gutter={16}>
          <Col span={10}>
            <Card
              title="Usuarios"
              extra={
                <Link to={"/users"}>
                  <RightOutlined />
                </Link>
              }
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Statistic
                    title="Activos"
                    value={countUserActive}
                    precision={0}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<UserOutlined />}
                    suffix=""
                  />
                </Col>
                <Col span={12}>
                  <Statistic
                    title="Inactivos"
                    value={countUserInactive}
                    precision={0}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<UserDeleteOutlined />}
                    suffix=""
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={7}>
            <Card
              title="Registros"
              extra={
                <Link to={"/encuestas"}>
                  <RightOutlined />
                </Link>
              }
            >
              <Statistic
                title="Realizados"
                value={countEncuestas}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<BarsOutlined />}
                suffix=""
              />
            </Card>
          </Col>
          <Col span={7}>
            <Card
              title="Alertas"
              extra={
                <Link to={"/fincas"}>
                  <RightOutlined />
                </Link>
              }
            >
              <Statistic
                title="Generadas"
                value={countFincas}
                precision={0}
                valueStyle={{ color: "#3f8600" }}
                prefix={<BankOutlined />}
                suffix=""
              />
            </Card>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <Card
              title="Nacimientos y muertes por mes"
              extra={
                <Link to={"/mapa/geoposicion"}>
                  <RightOutlined />
                </Link>
              }
            >
              {/* <MapContainer
                style={{ width: "100%", height: "70vh" }}
                center={position}
                zoom={6}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <MarkerClusterGroup
                  iconCreateFunction={createClusterCustomIcon}
                >
                  {fincas && fincas.map
                    ? fincas.map(
                        (item) =>
                          item.center && (
                            <Marker
                              key={item.id}
                              position={item.center}
                              //icon={iconFinca}
                            >
                              <Popup>
                                <b>{item.name}</b> <br /> {item.productor}
                              </Popup>
                            </Marker>
                          )
                      )
                    : ""}
                </MarkerClusterGroup> }
              </MapContainer> */}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
