import { basePath } from "./config";

// export function getCantonesApi(token, tipoId) {
//   const url = `${basePath}/cantons/${tipoId}`;

//   const params = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//   };

//   return fetch(url, params)
//     .then((response) => {
//       return response.json();
//     })
//     .then((result) => {
//       return result;
//     })
//     .catch((err) => {
//       return err.message;
//     });
// }

export function getAllCantonesApi(token) {
  const url = `${basePath}/cantones`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UpdateCantonApi(token, canton, cantonId) {
  const url = `${basePath}/canton/update/${cantonId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(canton),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddCantonApi(token, canton) {
  const url = `${basePath}/canton/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(canton),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteCantonApi(token, cantonId) {
  const url = `${basePath}/canton/delete/${cantonId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
