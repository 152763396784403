import React from "react";
import { Layout, Tabs } from "antd";
//import { Redirect } from "react-router-dom";

import Logo from "../../../assets/img/png/logo-white-textwhite.png";
import RegisterForm from "../../../components/Admin/RegisterForm";
import LoginForm from "../../../components/Admin/LoginForm";

import { getAccessTokenApi } from "../../../api/auth";

import "./SignIn.scss";
//import { Content } from "antd/lib/layout/layout";
import { Redirect } from "react-router";

export default function SignIn() {
  const { Content, Footer } = Layout;
  const { TabPane } = Tabs;

  if (getAccessTokenApi()) {
    return <Redirect to="/" />;
  }
  return (
    <Layout className="sign-in">
      <Content className="sign-in__content">
        <h1 className="sign-in__content-logo">
          <img src={Logo} alt="CI" />
        </h1>
        <h2 className="sign-in__content-subtitle">Proyecto crianza de cuyes</h2>
        <div className="sign-in__content-tabs">
          <Tabs type="card">
            <TabPane tab={<span>Ingresar</span>} key="1">
              <LoginForm />
            </TabPane>
            <TabPane tab={<span>Registrar</span>} key="2">
              <RegisterForm />
            </TabPane>
          </Tabs>
        </div>
      </Content>
      <Footer className="sign-in__footer">© 2021 Ayuda en acción</Footer>
    </Layout>
  );
}
