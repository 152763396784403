import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getCiclosApi } from "../../../../api/ciclo.api";

import "./SelectCicloForm.scss";

const { Option } = Select;

export default function SelectCicloForm(props) {
  const { tipoData, setTipoData, addSlag, disabled } = props;
  const [cicloData, setCicloData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [cicloId, setCicloId] = useState();

  useEffect(() => {
    getCiclosApi(token).then((response) => {
      //console.log(tipoData);
      setCicloData(response.ciclos);
      setCicloId(tipoData.cicloId);
      // if (addSlag === "add") {
      //   setCicloId(null);
      // } else {
      //   setCicloId(tipoData.cicloId);
      // }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, tipoData]);

  const handleAtributoChange = (value) => {
    setCicloId(value);
    setTipoData({
      ...tipoData,
      cicloId: value,
    });
  };

  return (
    <div className="select-ciclo-form">
      <Select
        placeholder="Seleccione la ciclo"
        value={cicloId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {cicloData && cicloData.map
          ? cicloData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          : ""}
      </Select>
    </div>
  );
}
