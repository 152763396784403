import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification, Checkbox } from "antd";
import SelectCantonForm from "../../Canton/SelectCantonForm";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateParroquiaApi,
  AddParroquiaApi,
  DeleteParroquiaApi,
} from "../../../../api/parroquia.api";

import "./EditParroquiaForm.scss";

export default function EditParroquiaForm(props) {
  const {
    parroquia,
    setIsVisibleModal,
    setReloadParroquias,
    buttonName,
    addSlag,
  } = props;
  const [parroquiaData, setParroquiaData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  //const [value, setValue] = useState("");
  const [checked, setChecked] = useState(false);

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    //setValue("");

    setParroquiaData({
      name: "",
      cantonId: null,
    });
  };

  useEffect(() => {
    if (parroquia) {
      setParroquiaData({
        id: parroquia.id,
        name: parroquia.name,
        cantonId: parroquia.cantonId,
        urbana: parroquia.urbana,
      });
      //setValue(parroquia.description);
      setChecked(parroquia.urbana);
    } else {
      //setValue("");
      setChecked(false);
      setParroquiaData({
        id: null,
        name: "",
        cantonId: null,
        urbana: null,
      });
    }
  }, [parroquia]);

  const onChange = (e) => {
    //console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
    setParroquiaData({ ...parroquiaData, urbana: e.target.checked });
  };

  const addUpdateParroquia = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();

    setParroquiaData({ ...parroquiaData, urbana: checked });

    let parroquiaUpdate = parroquiaData;
    //parroquiaUpdate.description = value;
    if (!parroquiaUpdate.cantonId && addSlag !== "delete") {
      notification["error"]({
        message: "El canton es obligatorio",
      });
      return;
    }

    if (!parroquiaUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddParroquiaApi(token, parroquiaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadParroquias(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateParroquiaApi(token, parroquiaUpdate, parroquia.id).then(
        (result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadParroquias(true);
          setIsVisibleModal(false);
          // setParroquiaData({
          //   ...parroquiaData,
          // });
        }
      );
    } else {
      DeleteParroquiaApi(token, parroquia.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadParroquias(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-parroquia-form">
      <form className="form-edit" onSubmit={addUpdateParroquia}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <SelectCantonForm
                setParroquiaData={setParroquiaData}
                parroquiaData={parroquiaData}
                addSlag={addSlag}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                disabled={addSlag === "delete" ? true : false}
              ></SelectCantonForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={parroquiaData.name}
                onChange={(e) =>
                  setParroquiaData({ ...parroquiaData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Checkbox onChange={onChange} checked={checked}>
                Parroquia urbana
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
