import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Slider,
  InputNumber,
} from "antd";
import SelectCicloForm from "../../Ciclo/SelectCicloForm";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateTipoApi,
  AddTipoApi,
  DeleteTipoApi,
} from "../../../../api/tipo.api";

import "./EditTipoForm.scss";

export default function EditTipoForm(props) {
  const { tipo, setIsVisibleModal, setReloadTipos, buttonName, addSlag } =
    props;
  const [tipoData, setTipoData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  //const [value, setValue] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    //setValue("");
    setMin(0);
    setMax(0);

    setTipoData({
      name: "",
      cicloId: null,
      description: "",
      observation: "",
      min: null,
      max: null,
    });
  };

  useEffect(() => {
    if (tipo) {
      setTipoData({
        id: tipo.id,
        name: tipo.name,
        cicloId: tipo.cicloId,
        description: tipo.description,
        observation: tipo.observation,
        min: tipo.min,
        max: tipo.max,
      });
      setMin(tipo.min);
      setMax(tipo.max);
      //setValue(tipo.description);
    } else {
      //setValue("");
      setMin(0);
      setMax(0);
      setTipoData({
        id: null,
        name: "",
        cicloId: null,
        description: "",
        observation: "",
        min: null,
        max: null,
      });
    }
  }, [tipo]);

  const addUpdateTipo = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();

    setTipoData({ ...tipoData, min: min, max: max });

    let tipoUpdate = tipoData;
    tipoUpdate.min = min;
    tipoUpdate.max = max;

    if (!tipoUpdate.cicloId && addSlag !== "delete") {
      notification["error"]({
        message: "El ciclo es obligatorio",
      });
      return;
    }

    if (!tipoUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddTipoApi(token, tipoUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadTipos(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateTipoApi(token, tipoUpdate, tipo.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadTipos(true);
        setIsVisibleModal(false);
        // setTipoData({
        //   ...tipoData,
        // });
      });
    } else {
      DeleteTipoApi(token, tipo.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadTipos(true);
        setIsVisibleModal(false);
      });
    }
  };
  const onChangeMin = (value) => {
    setMin(value);
  };
  const onChangeMax = (value) => {
    setMax(value);
  };
  return (
    <div className="edit-tipo-form">
      <form className="form-edit" onSubmit={addUpdateTipo}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <SelectCicloForm
                setTipoData={setTipoData}
                tipoData={tipoData}
                addSlag={addSlag}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                disabled={addSlag === "delete" ? true : false}
              ></SelectCicloForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={tipoData.name}
                onChange={(e) =>
                  setTipoData({ ...tipoData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Descripción"
                value={tipoData.description}
                onChange={(e) =>
                  setTipoData({ ...tipoData, description: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Observación"
                value={tipoData.observation}
                onChange={(e) =>
                  setTipoData({ ...tipoData, observation: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={6}>Mínimo (días)</Col>
          <Col span={12}>
            <Slider
              min={0}
              max={365}
              onChange={onChangeMin}
              value={typeof min === "number" ? min : 0}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={0}
              max={365}
              style={{ margin: "0 16px" }}
              value={min}
              onChange={onChangeMin}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={6}>Máximo (días)</Col>
          <Col span={12}>
            <Slider
              min={min}
              max={365}
              onChange={onChangeMax}
              value={typeof max === "number" ? max : 0}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={min}
              max={365}
              style={{ margin: "0 16px" }}
              value={max}
              onChange={onChangeMax}
            />
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
