import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateProvinciasApi,
  AddProvinciaApi,
  DeleteProvinciaApi,
} from "../../../../api/provincia.api";

import "./EditProvinciaForm.scss";

export default function EditProvinciaForm(props) {
  const {
    provincia,
    setIsVisibleModal,
    setReloadProvincias,
    buttonName,
    addSlag,
  } = props;
  const [provinciaData, setProvinciaData] = useState({});

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setProvinciaData({
      name: "",
    });
  };

  useEffect(() => {
    if (provincia) {
      setProvinciaData({
        id: provincia.id,
        name: provincia.name,
      });
    } else {
      setProvinciaData({
        id: null,
        name: "",
      });
    }
  }, [provincia]);

  const addUpdateProvincia = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let provinciaUpdate = provinciaData;
    if (!provinciaUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddProvinciaApi(token, provinciaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadProvincias(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateProvinciasApi(token, provinciaUpdate, provincia.id).then(
        (result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadProvincias(true);
          setIsVisibleModal(false);
          setProvinciaData({
            ...provinciaData,
          });
        }
      );
    } else {
      DeleteProvinciaApi(token, provincia.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadProvincias(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-ambito-form">
      <form className="form-edit" onSubmit={addUpdateProvincia}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={provinciaData.name}
                onChange={(e) =>
                  setProvinciaData({
                    ...provinciaData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
