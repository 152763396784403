import React from "react";
import { Modal } from "antd";

export default function ModalUser(props) {
  const { children, title, isVisibleModal, setIsVisibleModal, width } = props;
  const handleCancel = () => {
    setIsVisibleModal(false);
  };
  return (
    <>
      <Modal
        title={title}
        centered={true}
        visible={isVisibleModal}
        onCancel={handleCancel}
        footer={false}
        animation={false}
        width={width ? width : 1200}
      >
        {children}
      </Modal>
    </>
  );
}
