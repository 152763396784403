import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateCuyerasApi,
  AddCuyeraApi,
  DeleteCuyeraApi,
} from "../../../../api/cuyera.api";

import "./EditCuyeraForm.scss";

export default function EditCuyeraForm(props) {
  const { cuyera, setIsVisibleModal, setReloadCuyeras, buttonName, addSlag } =
    props;
  const [cuyeraData, setCuyeraData] = useState({});

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setCuyeraData({
      description: "",
      name: "",
      icon: "",
    });
  };

  useEffect(() => {
    if (cuyera) {
      setCuyeraData({
        id: cuyera.id,
        description: cuyera.description,
        name: cuyera.name,
      });
    } else {
      setCuyeraData({
        id: null,
        description: "",
        name: "",
      });
    }
  }, [cuyera]);

  const addUpdateCuyera = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let cuyeraUpdate = cuyeraData;
    if (!cuyeraUpdate.description || !cuyeraUpdate.name) {
      notification["error"]({
        message: "El nombre y descripción es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddCuyeraApi(token, cuyeraUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCuyeras(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateCuyerasApi(token, cuyeraUpdate, cuyera.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCuyeras(true);
        setIsVisibleModal(false);
        setCuyeraData({
          ...cuyeraData,
        });
      });
    } else {
      DeleteCuyeraApi(token, cuyera.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCuyeras(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-ambito-form">
      <form className="form-edit" onSubmit={addUpdateCuyera}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={cuyeraData.name}
                onChange={(e) =>
                  setCuyeraData({
                    ...cuyeraData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Description"
                value={cuyeraData.description}
                onChange={(e) =>
                  setCuyeraData({
                    ...cuyeraData,
                    description: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
