import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";

import HomeOutlined from "@ant-design/icons/HomeOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import ToolOutlined from "@ant-design/icons/ToolOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import TableOutlined from "@ant-design/icons/TableOutlined";
import FolderOutlined from "@ant-design/icons/FolderOutlined";
import FundOutlined from "@ant-design/icons/FundOutlined";
//import BarChartOutlined from "@ant-design/icons/BarChartOutlined";
import RadarChartOutlined from "@ant-design/icons/RadarChartOutlined";
import ClearOutlined from "@ant-design/icons/ClearOutlined";
import SwapRightOutlined from "@ant-design/icons/SwapRightOutlined";
import BugOutlined from "@ant-design/icons/BugOutlined";
import CopyOutlined from "@ant-design/icons/CopyOutlined";

// import BankOutlined from "@ant-design/icons/BankOutlined";
// import BarsOutlined from "@ant-design/icons/BarsOutlined";

import "./MenuSider.scss";

function MenuSider(props) {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const { menuCollapsed, location } = props;

  return (
    <Sider className="admin-sider" theme="light" collapsed={menuCollapsed}>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <Link to={"/"}>
            <HomeOutlined />
            <span className="nav-text">Principal</span>
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="/fincas">
          <Link to={"/fincas"}>
            <BankOutlined />
            <span className="nav-text">Fincas</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/encuestas">
          <Link to={"/encuestas"}>
            <BarsOutlined />
            <span className="nav-text">Registro de datos</span>
          </Link>
        </Menu.Item> */}
        <SubMenu key="sub1" icon={<ToolOutlined />} title="Catálogos">
          <SubMenu key="sub5" icon={<FolderOutlined />} title="Consultas">
            <Menu.Item key="/consultas/tipo" icon={<SwapRightOutlined />}>
              <Link to={"/consultas/tipo"}>
                <span className="nav-text">Tipos</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/consultas/registro" icon={<SwapRightOutlined />}>
              <Link to={"/consultas/registro"}>
                <span className="nav-text">Registros</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub6" icon={<FolderOutlined />} title="Ubicaciones">
            <Menu.Item key="/ubicacion/provincia" icon={<SwapRightOutlined />}>
              <Link to={"/ubicacion/provincia"}>
                <span className="nav-text">Provincia</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/ubicacion/canton" icon={<SwapRightOutlined />}>
              <Link to={"/ubicacion/canton"}>
                <span className="nav-text">Cantón</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/ubicacion/parroquia" icon={<SwapRightOutlined />}>
              <Link to={"/ubicacion/parroquia"}>
                <span className="nav-text">Parroquia</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/ubicacion/comunidad" icon={<SwapRightOutlined />}>
              <Link to={"/ubicacion/comunidad"}>
                <span className="nav-text">Comunidad</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="sub7" icon={<FolderOutlined />} title="Alertas">
            <Menu.Item key="/alertas/ciclo" icon={<SwapRightOutlined />}>
              <Link to={"/alertas/ciclo"}>
                <span className="nav-text">Ciclo productivo del cuy</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/alertas/tipo" icon={<SwapRightOutlined />}>
              <Link to={"/alertas/tipo"}>
                <span className="nav-text">Tipo alertas</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="/cuyeras" icon={<TableOutlined />}>
            <Link to={"/cuyeras"}>
              <span className="nav-text">Cuyeras</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/limpieza" icon={<ClearOutlined />}>
            <Link to={"/limpieza"}>
              <span className="nav-text">Limpieza y desinfección</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/causa" icon={<BugOutlined />}>
            <Link to={"/causa"}>
              <span className="nav-text">Causa de muerte</span>
            </Link>
          </Menu.Item>
          {/* <SubMenu key="sub6" icon={<FolderOutlined />} title="Indicadores">
            <Menu.Item key="/indicadores/sostenibilidad">
              <Link to={"/indicadores/sostenibilidad"}>
                <span className="nav-text">Sostenibilidad</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/variables">
              <Link to={"/variables"}>
                <span className="nav-text">Secundarios</span>
              </Link>
            </Menu.Item>
            
          </SubMenu> */}
        </SubMenu>
        {/* <SubMenu key="sub2" icon={<AimOutlined />} title="Mapa">
          <Menu.Item key="/mapa/geoposicion">
            <Link to={"/mapa/geoposicion"}>
              <AimOutlined />
              <span className="nav-text">Geoposición</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/mapa/zonas">
            <Link to={"/mapa/zonas"}>
              <HeatMapOutlined />
              <span className="nav-text">Zonas</span>
            </Link>
          </Menu.Item>
        </SubMenu> */}
        <SubMenu key="sub8" icon={<CopyOutlined />} title="Consultas">
          <Menu.Item key="/consultas/cuyeras">
            <Link to={"/consultas/cuyeras"}>
              <SwapRightOutlined />
              <span className="nav-text">Cuyeras</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/consultas/nacimientos">
            <Link to={"/consultas/nacimientos"}>
              <SwapRightOutlined />
              <span className="nav-text">Nacimientos</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/consultas/comercio">
            <Link to={"/consultas/comercio"}>
              <SwapRightOutlined />
              <span className="nav-text">Comercio</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="/consultas/muertes">
            <Link to={"/consultas/muertes"}>
              <SwapRightOutlined />
              <span className="nav-text">Muertes</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub3" icon={<FundOutlined />} title="Reportes">
          <Menu.Item key="/reportes/ambito">
            <Link to={"/reportes/ambito"}>
              <RadarChartOutlined />
              <span className="nav-text">Cuyeras</span>
            </Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub4" icon={<SettingOutlined />} title="Administración">
          <Menu.Item key="/users">
            <Link to={"/users"}>
              <UserOutlined />
              <span className="nav-text">Usuarios</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
}

export default withRouter(MenuSider);
