import { basePath } from "./config";

// export function getRegistrosApi(token, tipoId) {
//   const url = `${basePath}/registros/${tipoId}`;

//   const params = {
//     method: "GET",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: token,
//     },
//   };

//   return fetch(url, params)
//     .then((response) => {
//       return response.json();
//     })
//     .then((result) => {
//       return result;
//     })
//     .catch((err) => {
//       return err.message;
//     });
// }

export function getAllRegistrosApi(token) {
  const url = `${basePath}/registros`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UpdateRegistroApi(token, registro, registroId) {
  const url = `${basePath}/registro/update/${registroId}`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(registro),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function AddRegistroApi(token, registro) {
  const url = `${basePath}/registro/create`;

  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(registro),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function DeleteRegistroApi(token, registroId) {
  const url = `${basePath}/registro/delete/${registroId}`;

  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function UploadAudioApi(token, audio, registroId) {
  const url = `${basePath}/upload-audio/${registroId}`;

  const formData = new FormData();
  console.log(audio);
  formData.append("audio", audio, audio.name);

  const params = {
    method: "PUT",
    body: formData,
    headers: {
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
