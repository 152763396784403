import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb, Input, Space } from "antd";
import Highlighter from "react-highlight-words";
import { getAccessTokenApi } from "../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

import "./Comunidad.scss";

import ModalUser from "../../../components/Admin/ModalUser";
import EditComunidadForm from "../../../components/Admin/Comunidad/EditComunidadForm";
import { getAllComunidadsApi } from "../../../api/comunidad.api";

export default function Comunidad() {
  const [comunidads, setComunidads] = useState([]);
  const token = getAccessTokenApi();
  const [reloadComunidads, setReloadComunidads] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });
  var searchInput = null;

  useEffect(() => {
    getAllComunidadsApi(token).then((response) => {
      setComunidads(response.comunidads);
      setLoadingData(false);
    });

    setReloadComunidads(false);
  }, [token, reloadComunidads]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filtrar
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
    {
      title: "Nombre",
      width: 80,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Provincia",
      width: 100,
      dataIndex: ["parroquia", "canton", "provincia", "name"],
      key: "parroquia.canton.provinciaId",
    },
    {
      title: "Canton",
      width: 100,
      dataIndex: ["parroquia", "canton", "name"],
      key: "parroquia.cantonId",
    },
    {
      title: "Parroquia",
      width: 100,
      dataIndex: ["parroquia", "name"],
      key: "parroquiaId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editComunidad(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteComunidad(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addComunidad = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nueva comunidad");
    setDisabled(true);
    setmodalContent(
      <EditComunidadForm
        comunidad={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadComunidads={setReloadComunidads}
        buttonName="Agregar comunidad"
        addSlag={"add"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  const editComunidad = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar comunidad");
    setDisabled(false);
    setmodalContent(
      <EditComunidadForm
        comunidad={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadComunidads={setReloadComunidads}
        buttonName="Editar comunidad"
        addSlag={"edit"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };

  const deleteComunidad = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar comunidad");
    setDisabled(true);
    setmodalContent(
      <EditComunidadForm
        comunidad={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadComunidads={setReloadComunidads}
        buttonName="Eliminar comunidad"
        addSlag={"delete"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  return (
    <div className="comunidads">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Ubicación</Breadcrumb.Item>
        <Breadcrumb.Item>Comunidad</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addComunidad}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={comunidads}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen comunidades"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={600}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
