import React, { useState, useEffect } from "react";
import { Switch, List, Avatar, Button, notification, Empty } from "antd";
import noAvatar from "../../../../assets/img/png/no-avatar.png";
import ModalUser from "../../ModalUser";
import EditUserForm from "../EditUserForm";
import EditOutlined from "@ant-design/icons/EditOutlined";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import StopOutlined from "@ant-design/icons/StopOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import { getAvatarApi, activateUserApi } from "../../../../api/user.api";

import { getAccessTokenApi } from "../../../../api/auth";

import "./ListUsers.scss";

export default function ListUsers(props) {
  const { usersActive, usersInactive, setReloadUsers } = props;
  const [viewUsersActive, setViewUsersActive] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();

  return (
    <div className="list-users">
      <div className="list-users__switch">
        <Switch
          defaultChecked
          onChange={(e) => setViewUsersActive(!viewUsersActive)}
        />
        <span>
          {viewUsersActive ? "Usuarios activos" : "Usuarios inactivos"}
        </span>
      </div>
      {viewUsersActive ? (
        <UsersActive
          usersActive={usersActive}
          setIsVisibleModal={setIsVisibleModal}
          setModalTitle={setModalTitle}
          setmodalContent={setmodalContent}
          setReloadUsers={setReloadUsers}
        />
      ) : (
        <UsersInactive
          usersInactive={usersInactive}
          setReloadUsers={setReloadUsers}
        />
      )}
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}

function UsersActive(props) {
  const {
    usersActive,
    setModalTitle,
    setIsVisibleModal,
    setmodalContent,
    setReloadUsers,
  } = props;

  const editUser = (user) => {
    setIsVisibleModal(true);
    setModalTitle(
      `Editar ${user.name ? user.name : "-"} ${
        user.lastname ? user.lastname : "-"
      }`
    );
    setmodalContent(
      <EditUserForm
        user={user}
        setIsVisibleModal={setIsVisibleModal}
        setReloadUsers={setReloadUsers}
      />
    );
  };

  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersActive}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen usuarios activos"
          />
        ),
      }}
      renderItem={(user) => (
        <UserActive
          user={user}
          editUser={editUser}
          setReloadUsers={setReloadUsers}
        />
      )}
    />
  );
}

function UserActive(props) {
  const { user, editUser, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const desactivateUser = () => {
    const accessToken = getAccessTokenApi();
    activateUserApi(accessToken, user.id, false)
      .then((response) => {
        notification["success"]({
          message: response,
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={() => editUser(user)}>
          <EditOutlined />
        </Button>,
        <Button type="danger" onClick={() => console.log("Borrar")}>
          <DeleteOutlined />
        </Button>,
        <Button
          type="danger"
          title="Desactivar usuario"
          onClick={desactivateUser}
        >
          <StopOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : noAvatar} />}
        title={`
                ${user.name ? user.name : "-"} 
                ${user.lastname ? user.lastname : "-"}
     
                (${user.cedula ? user.cedula : "-"})
            `}
        description={user.email}
      />
    </List.Item>
  );
}

function UsersInactive(props) {
  const { usersInactive, setReloadUsers } = props;
  return (
    <List
      className="users-active"
      itemLayout="horizontal"
      dataSource={usersInactive}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No existen usuarios inactivos"
          />
        ),
      }}
      renderItem={(user) => (
        <UserInactive user={user} setReloadUsers={setReloadUsers} />
      )}
    />
  );
}

function UserInactive(props) {
  const { user, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then((response) => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const activateUser = () => {
    const accessToken = getAccessTokenApi();
    activateUserApi(accessToken, user.id, true)
      .then((response) => {
        console.log(response);
        notification["success"]({
          message: response,
        });
        setReloadUsers(true);
      })
      .catch((err) => {
        notification["error"]({
          message: err,
        });
      });
  };

  return (
    <List.Item
      actions={[
        <Button type="danger" onClick={() => console.log("Borrar")}>
          <DeleteOutlined />
        </Button>,
        <Button type="primary" title="Activar usuario" onClick={activateUser}>
          <CheckOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar src={avatar ? avatar : noAvatar} />}
        title={`
                  ${user.name ? user.name : "-"} 
                  ${user.lastname ? user.lastname : "-"}
     
                  (${user.cedula ? user.cedula : "-"})
              `}
        description={user.email}
      />
    </List.Item>
  );
}
