import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import { Table, Button, Empty, Breadcrumb, Affix } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Cuyera.scss";
import { getCuyerasApi } from "../../../api/cuyera.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditCuyeraForm from "../../../components/Admin/Cuyera/EditCuyeraForm";

export default function Cuyera() {
  const [cuyera, setCuyera] = useState([]);
  const token = getAccessTokenApi();
  const [reloadCuyeras, setReloadCuyeras] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getCuyerasApi(token, true).then((response) => {
      setCuyera(response.cuyeras);
      setLoadingData(false);
    });

    setReloadCuyeras(false);
  }, [token, reloadCuyeras]);

  const addCuyera = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nueva cuyera");
    setmodalContent(
      <EditCuyeraForm
        cuyera={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCuyeras={setReloadCuyeras}
        buttonName="Agregar cuyera"
        addSlag={"add"}
      />
    );
  };

  const editCuyera = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar cuyera");
    setmodalContent(
      <EditCuyeraForm
        cuyera={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCuyeras={setReloadCuyeras}
        buttonName="Editar cuyera"
        addSlag={"edit"}
      />
    );
  };

  const deleteCuyera = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar cuyera");
    setmodalContent(
      <EditCuyeraForm
        cuyera={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCuyeras={setReloadCuyeras}
        buttonName="Eliminar cuyera"
        addSlag={"delete"}
      />
    );
  };

  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Descripción",
      width: 100,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editCuyera(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteCuyera(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="cuyera">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Cuyeras</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addCuyera}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={cuyera}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen cuyeras"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
