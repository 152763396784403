import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateCiclosApi,
  AddCicloApi,
  DeleteCicloApi,
} from "../../../../api/ciclo.api";

import "./EditCicloForm.scss";

export default function EditCicloForm(props) {
  const { ciclo, setIsVisibleModal, setReloadCiclos, buttonName, addSlag } =
    props;
  const [cicloData, setCicloData] = useState({});

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setCicloData({
      name: "",
    });
  };

  useEffect(() => {
    if (ciclo) {
      setCicloData({
        id: ciclo.id,
        name: ciclo.name,
      });
    } else {
      setCicloData({
        id: null,
        name: "",
      });
    }
  }, [ciclo]);

  const addUpdateCiclo = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let cicloUpdate = cicloData;
    if (!cicloUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddCicloApi(token, cicloUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCiclos(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateCiclosApi(token, cicloUpdate, ciclo.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCiclos(true);
        setIsVisibleModal(false);
        setCicloData({
          ...cicloData,
        });
      });
    } else {
      DeleteCicloApi(token, ciclo.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCiclos(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-ambito-form">
      <form className="form-edit" onSubmit={addUpdateCiclo}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={cicloData.name}
                onChange={(e) =>
                  setCicloData({
                    ...cicloData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
