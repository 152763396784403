import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Upload,
  //message,
} from "antd";
import SelectTipoConsultaForm from "../../TipoConsulta/SelectTipoConsultaForm";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateRegistroApi,
  AddRegistroApi,
  DeleteRegistroApi,
  UploadAudioApi,
} from "../../../../api/registro.api";

import "./EditRegistroForm.scss";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { UploadOutlined } from "@ant-design/icons";

export default function EditRegistroForm(props) {
  const {
    registro,
    setIsVisibleModal,
    setReloadRegistros,
    buttonName,
    addSlag,
  } = props;
  const [registroData, setRegistroData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [value, setValue] = useState("");
  const [state, setState] = useState([]);
  const [audio, setAudio] = useState();
  const [audioname, setAudioname] = useState("");

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setValue("");

    setRegistroData({
      title: "",
      description: "",
      tipoId: null,
      audio: "",
      audioname: "",
    });
  };

  useEffect(() => {
    if (audio) {
      setRegistroData({ ...registroData, audio: audio });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  useEffect(() => {
    if (registro) {
      setRegistroData({
        id: registro.id,
        title: registro.title,
        description: registro.description,
        audio: registro.audio,
        audioname: registro.audioname,
        tipoId: registro.tipoId,
      });
      if (registro.audio) {
        setState([
          {
            uid: "-1",
            name: registro.audio,
            status: "done",
            url: "",
          },
        ]);
        //console.log(state);
      } else {
        setState([]);
      }
      setValue(registro.description);
    } else {
      setValue("");
      setState([]);
      setRegistroData({
        id: null,
        title: "",
        description: "",
        tipoId: null,
        audio: "",
        audioname: "",
      });
    }
  }, [registro]);

  const addUpdateRegistro = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    if (audio) {
      setRegistroData({ ...registroData, audio: audio });
    }

    setRegistroData({ ...registroData, description: value });

    let registroUpdate = registroData;
    registroUpdate.description = value;

    if (audio && addSlag === "edit") {
      registroUpdate.audio = audio;
    }

    if (!registroUpdate.tipoId && addSlag !== "delete") {
      notification["error"]({
        message: "El tipo es obligatorio",
      });
      return;
    }

    if (!registroUpdate.title) {
      notification["error"]({
        message: "El título es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      registroUpdate.audio = "";
      AddRegistroApi(token, registroUpdate).then((result) => {
        if (audio) {
          UploadAudioApi(token, audio, result.registro.id).then((response) => {
            registroUpdate.audio = response.audioname;
            setReloadRegistros(true);
            setIsVisibleModal(false);
            resetForm();
          });
          notification["success"]({
            message: result.message,
          });
        } else {
          notification["success"]({
            message: result.message,
          });
          setReloadRegistros(true);
          setIsVisibleModal(false);
          resetForm();
        }
      });
    } else if (addSlag === "edit") {
      if (registroUpdate.audio) {
        UploadAudioApi(token, registroUpdate.audio, registro.id).then(
          (response) => {
            registroUpdate.audio = response.audioname;
            UpdateRegistroApi(token, registroUpdate, registro.id).then(
              (result) => {
                notification["success"]({
                  message: result.message,
                });
                setReloadRegistros(true);
                setIsVisibleModal(false);
              }
            );
          }
        );
      } else {
        UpdateRegistroApi(token, registroUpdate, registro.id).then((result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadRegistros(true);
          setIsVisibleModal(false);
          // setRegistroData({
          //   ...registroData,
          // });
        });
      }
    } else {
      DeleteRegistroApi(token, registro.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadRegistros(true);
        setIsVisibleModal(false);
      });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      // ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  // var bufferToBase64 = function (buffer) {
  //   var bytes = new Uint8Array(buffer);
  //   var len = buffer.byteLength;
  //   var binary = "";
  //   for (var i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return window.btoa(binary);
  // };
  // var base64ToBuffer = function (buffer) {
  //   var binary = window.atob(buffer);
  //   var buffer2 = new ArrayBuffer(binary.length);
  //   var bytes = new Uint8Array(buffer2);
  //   for (var i = 0; i < buffer2.byteLength; i++) {
  //     bytes[i] = binary.charCodeAt(i) & 0xff;
  //   }
  //   return buffer2;
  // };

  const props2 = {
    customRequest: dummyRequest,
    //onChange: handleChange,
    onChange: (info) => {
      if (info.fileList.length > 0) {
        // var reader = new FileReader();

        // reader.onload = function (e) {
        //   console.log(e);
        //   var base64String = bufferToBase64(e.target.result);
        //   //var audioFromString = base64ToBuffer(base64String);
        //   console.log(base64String);
        //   setAudio(base64String);
        //   // context.decodeAudioData(
        //   //   audioFromString,
        //   //   function (buffer) {
        //   //     // audioBuffer is global to reuse the decoded audio later.
        //   //     //audioBuffer = buffer;
        //   //     setAudio(buffer);
        //   //     // var buttons = document.querySelectorAll("button");
        //   //     // buttons[0].disabled = false;
        //   //     // buttons[1].disabled = false;
        //   //   },
        //   //   function (e) {
        //   //     console.log("Error decoding file", e);
        //   //   }
        //   // );
        // };
        // reader.readAsArrayBuffer(info.fileList[0].originFileObj);
        // //console.log(info.fileList[0]);
        // setAudioname(info.fileList[0].name);
        setState(info.fileList);
        const file = info.fileList[0].originFileObj;
        setAudio(file);
      } else {
        setAudio(null);
        setState([]);
      }
    },
    accept: "audio/mp3",
    maxCount: 1,
    // beforeUpload: (file) => {
    //   console.log(file);
    //   if (file.type.index !== "audio/mp3") {
    //     message.error(`${file.name} no es un archivo de audio`);
    //   }
    //   return file.type === "image/png" ? true : Upload.LIST_IGNORE;
    // },
  };

  return (
    <div className="edit-registro-form">
      <form className="form-edit" onSubmit={addUpdateRegistro}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <SelectTipoConsultaForm
                setRegistroData={setRegistroData}
                registroData={registroData}
                addSlag={addSlag}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                disabled={addSlag === "delete" ? true : false}
              ></SelectTipoConsultaForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Título"
                value={registroData.title}
                onChange={(e) =>
                  setRegistroData({ ...registroData, title: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={24}>
            <ReactQuill
              theme="snow"
              value={value}
              onChange={setValue}
              modules={modules}
              formats={formats}
            />
          </Col>
        </Row>
        <br /> <br />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item label="Audio:">
              <Upload {...props2} fileList={state}>
                <Button htmlType="button" icon={<UploadOutlined />}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
