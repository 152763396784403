import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import SelectProvinciaForm from "../../Provincia/SelectProvinciaForm";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateCantonApi,
  AddCantonApi,
  DeleteCantonApi,
} from "../../../../api/canton.api";

import "./EditCantonForm.scss";

export default function EditCantonForm(props) {
  const { canton, setIsVisibleModal, setReloadCantones, buttonName, addSlag } =
    props;
  const [cantonData, setCantonData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [value, setValue] = useState("");

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setValue("");

    setCantonData({
      name: "",
      provinciaId: null,
    });
  };

  useEffect(() => {
    if (canton) {
      setCantonData({
        id: canton.id,
        name: canton.name,
        provinciaId: canton.provinciaId,
      });
      setValue(canton.description);
    } else {
      setValue("");

      setCantonData({
        id: null,
        name: "",
        provinciaId: null,
      });
    }
  }, [canton]);

  const addUpdateCanton = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();

    setCantonData({ ...cantonData, description: value });

    let cantonUpdate = cantonData;
    cantonUpdate.description = value;
    if (!cantonUpdate.provinciaId && addSlag !== "delete") {
      notification["error"]({
        message: "La provincia es obligatorio",
      });
      return;
    }

    if (!cantonUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddCantonApi(token, cantonUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCantones(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateCantonApi(token, cantonUpdate, canton.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCantones(true);
        setIsVisibleModal(false);
        // setCantonData({
        //   ...cantonData,
        // });
      });
    } else {
      DeleteCantonApi(token, canton.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCantones(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-canton-form">
      <form className="form-edit" onSubmit={addUpdateCanton}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <SelectProvinciaForm
                setCantonData={setCantonData}
                cantonData={cantonData}
                addSlag={addSlag}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                disabled={addSlag === "delete" ? true : false}
              ></SelectProvinciaForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={cantonData.name}
                onChange={(e) =>
                  setCantonData({ ...cantonData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
