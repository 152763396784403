import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../../api/auth";

import { Table, Button, Empty, Breadcrumb, Affix } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./TipoConsulta.scss";
import { getTipoConsultasApi } from "../../../../api/tipoconsulta.api";

import ModalUser from "../../../../components/Admin/ModalUser";
import EditTipoConsultaForm from "../../../../components/Admin/TipoConsulta/EditTipoConsultaForm";

export default function TipoConsulta() {
  const [tipoconsulta, setTipoconsulta] = useState([]);
  const token = getAccessTokenApi();
  const [reloadTipoConsultas, setReloadTipoConsultas] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getTipoConsultasApi(token, true).then((response) => {
      setTipoconsulta(response.tipoconsultas);
      setLoadingData(false);
    });

    setReloadTipoConsultas(false);
  }, [token, reloadTipoConsultas]);

  const addTipoConsulta = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo tipo");
    setmodalContent(
      <EditTipoConsultaForm
        tipoconsulta={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTipoConsultas={setReloadTipoConsultas}
        buttonName="Agregar tipo"
        addSlag={"add"}
      />
    );
  };

  const editTipoconsulta = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar tipo");
    setmodalContent(
      <EditTipoConsultaForm
        tipoconsulta={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTipoConsultas={setReloadTipoConsultas}
        buttonName="Editar tipo"
        addSlag={"edit"}
      />
    );
  };

  const deleteTipoconsulta = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar tipo");
    setmodalContent(
      <EditTipoConsultaForm
        tipoconsulta={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTipoConsultas={setReloadTipoConsultas}
        buttonName="Eliminar tipo"
        addSlag={"delete"}
      />
    );
  };

  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Descripción",
      width: 100,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Ícono",
      width: 100,
      dataIndex: "icon",
      key: "icon",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editTipoconsulta(record)}
          >
            <EditOutlined />
          </Button>
          {record.id !== 1 && (
            <Button
              size="small"
              type="danger"
              onClick={() => deleteTipoconsulta(record)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="tipoconsulta">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Consultas</Breadcrumb.Item>
        <Breadcrumb.Item>Tipo</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addTipoConsulta}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={tipoconsulta}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen tipos"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
