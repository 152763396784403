import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getProvinciasApi } from "../../../../api/provincia.api";

import "./SelectProvinciaForm.scss";

const { Option } = Select;

export default function SelectProvinciaForm(props) {
  const { cantonData, setCantonData, addSlag, disabled } = props;
  const [provinciaData, setProvinciaData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [provinciaId, setTipoId] = useState();

  useEffect(() => {
    getProvinciasApi(token).then((response) => {
      setProvinciaData(response.provincias);
      setTipoId(cantonData.provinciaId);
      //   if (addSlag === "add") {
      //     setProvinciaId(null);
      //   } else {
      //     setProvinciaId(atributoData.provinciaId);
      //   }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, cantonData]);

  const handleAtributoChange = (value) => {
    setTipoId(value);
    setCantonData({
      ...cantonData,
      provinciaId: value,
    });
  };

  return (
    <div className="select-provincia-form">
      <Select
        placeholder="Seleccione la provincia"
        value={provinciaId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {provinciaData && provinciaData.map
          ? provinciaData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          : ""}
      </Select>
    </div>
  );
}
