import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateTipoConsultasApi,
  AddTipoConsultaApi,
  DeleteTipoConsultaApi,
} from "../../../../api/tipoconsulta.api";

import "./EditTipoConsultaForm.scss";

export default function EditTipoConsultaForm(props) {
  const {
    tipoconsulta,
    setIsVisibleModal,
    setReloadTipoConsultas,
    buttonName,
    addSlag,
  } = props;
  const [tipoconsultaData, setTipoconsultaData] = useState({});

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setTipoconsultaData({
      description: "",
      name: "",
      icon: "",
    });
  };

  useEffect(() => {
    if (tipoconsulta) {
      setTipoconsultaData({
        id: tipoconsulta.id,
        description: tipoconsulta.description,
        name: tipoconsulta.name,
        icon: tipoconsulta.icon,
      });
    } else {
      setTipoconsultaData({
        id: null,
        description: "",
        name: "",
        icon: "",
      });
    }
  }, [tipoconsulta]);

  const addUpdateTipoConsulta = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let tipoconsultaUpdate = tipoconsultaData;
    if (
      !tipoconsultaUpdate.description ||
      !tipoconsultaUpdate.name ||
      !tipoconsultaUpdate.icon
    ) {
      notification["error"]({
        message: "El nombre, ícono y descripción es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddTipoConsultaApi(token, tipoconsultaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadTipoConsultas(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateTipoConsultasApi(token, tipoconsultaUpdate, tipoconsulta.id).then(
        (result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadTipoConsultas(true);
          setIsVisibleModal(false);
          setTipoconsultaData({
            ...tipoconsultaData,
          });
        }
      );
    } else {
      DeleteTipoConsultaApi(token, tipoconsulta.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadTipoConsultas(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-ambito-form">
      <form className="form-edit" onSubmit={addUpdateTipoConsulta}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={tipoconsultaData.name}
                onChange={(e) =>
                  setTipoconsultaData({
                    ...tipoconsultaData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Ícono"
                value={tipoconsultaData.icon}
                onChange={(e) =>
                  setTipoconsultaData({
                    ...tipoconsultaData,
                    icon: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Description"
                value={tipoconsultaData.description}
                onChange={(e) =>
                  setTipoconsultaData({
                    ...tipoconsultaData,
                    description: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
