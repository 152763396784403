import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getTipoConsultasApi } from "../../../../api/tipoconsulta.api";

import "./SelectTipoConsultaForm.scss";

const { Option } = Select;

export default function SelectTipoConsultaForm(props) {
  const { registroData, setRegistroData, addSlag, disabled } = props;
  const [tipoConsultaData, setTipoConsultaData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [tipoId, setTipoId] = useState();

  useEffect(() => {
    getTipoConsultasApi(token).then((response) => {
      setTipoConsultaData(response.tipoconsultas);
      setTipoId(registroData.tipoId);
      //   if (addSlag === "add") {
      //     setTipoConsultaId(null);
      //   } else {
      //     setTipoConsultaId(atributoData.tipoConsultaId);
      //   }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, registroData]);

  const handleAtributoChange = (value) => {
    setTipoId(value);
    setRegistroData({
      ...registroData,
      tipoId: value,
    });
  };

  return (
    <div className="select-tipoConsulta-form">
      <Select
        placeholder="Seleccione el tipo"
        value={tipoId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {tipoConsultaData && tipoConsultaData.map
          ? tipoConsultaData.map(
              (item) =>
                item.id !== 1 && (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                )
            )
          : ""}
      </Select>
    </div>
  );
}
