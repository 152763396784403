//Layout
import LayoutAdmin from "../layouts/LayoutAdmin";
//import LayoutBasic from "../layouts/LayoutBasic";

//Admin pages
import AdminHome from "../pages/Admin";
import AdminSignIn from "../pages/Admin/SignIn";
import AdminUsers from "../pages/Admin/Users";
import AdminTipoConsulta from "../pages/Admin/Consultas/TipoConsulta";
import AdminRegistro from "../pages/Admin/Consultas/Registro";
import AdminCuyera from "../pages/Admin/Cuyera";
import AdminLimpieza from "../pages/Admin/Limpieza";
import AdminProvincia from "../pages/Admin/Provincia";
import AdminCanton from "../pages/Admin/Canton";
import AdminParroquia from "../pages/Admin/Parroquia";
import AdminComunidad from "../pages/Admin/Comunidad";
import AdminCiclo from "../pages/Admin/Ciclo";
import AdminTipo from "../pages/Admin/Tipo";
import AdminCausa from "../pages/Admin/Causa";
import AdminCuyeraConsulta from "../pages/Admin/Reportes/Cuyeras";
import AdminNacimientoConsulta from "../pages/Admin/Reportes/Nacimientos";
import AdminPesajeConsulta from "../pages/Admin/Reportes/Comercio";
import AdminMuertesConsulta from "../pages/Admin/Reportes/Muertes";

//Pages
// import Home from "../pages/Home";
// import Contact from "../pages/Contact";

//Other
import Error404 from "../pages/Error404";

const routes = [
  {
    path: "/",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/",
        component: AdminHome,
        exact: true,
      },
      {
        path: "/login",
        component: AdminSignIn,
        exact: true,
      },
      {
        path: "/users",
        component: AdminUsers,
        exact: true,
      },
      // {
      //   path: "/reportes/ambito",
      //   component: AdminIndicadoresAmbito,
      //   exact: true,
      // },
      {
        path: "/consultas/tipo",
        component: AdminTipoConsulta,
        exact: true,
      },
      {
        path: "/consultas/registro",
        component: AdminRegistro,
        exact: true,
      },
      {
        path: "/cuyeras",
        component: AdminCuyera,
        exact: true,
      },
      {
        path: "/limpieza",
        component: AdminLimpieza,
        exact: true,
      },
      {
        path: "/causa",
        component: AdminCausa,
        exact: true,
      },
      {
        path: "/ubicacion/provincia",
        component: AdminProvincia,
        exact: true,
      },
      {
        path: "/ubicacion/canton",
        component: AdminCanton,
        exact: true,
      },
      {
        path: "/ubicacion/parroquia",
        component: AdminParroquia,
        exact: true,
      },
      {
        path: "/ubicacion/comunidad",
        component: AdminComunidad,
        exact: true,
      },
      {
        path: "/alertas/ciclo",
        component: AdminCiclo,
        exact: true,
      },
      {
        path: "/alertas/tipo",
        component: AdminTipo,
        exact: true,
      },
      {
        path: "/consultas/cuyeras",
        component: AdminCuyeraConsulta,
        exact: true,
      },
      {
        path: "/consultas/nacimientos",
        component: AdminNacimientoConsulta,
        exact: true,
      },
      {
        path: "/consultas/comercio",
        component: AdminPesajeConsulta,
        exact: true,
      },
      {
        path: "/consultas/muertes",
        component: AdminMuertesConsulta,
        exact: true,
      },
      {
        component: Error404,
      },
    ],
  },
  // {
  //   path: "/",
  //   component: LayoutBasic,
  //   exact: false,
  //   routes: [
  //     {
  //       path: "/",
  //       component: Home,
  //       exact: true,
  //     },
  //     {
  //       path: "/contact",
  //       component: Contact,
  //       exact: true,
  //     },
  //     {
  //       component: Error404,
  //     },
  //   ],
  // },
];

export default routes;
