import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Canton.scss";

import ModalUser from "../../../components/Admin/ModalUser";
import EditCantonForm from "../../../components/Admin/Canton/EditCantonForm";
import { getAllCantonesApi } from "../../../api/canton.api";

export default function Canton() {
  const [cantones, setCantones] = useState([]);
  const token = getAccessTokenApi();
  const [reloadCantones, setReloadCantones] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getAllCantonesApi(token).then((response) => {
      setCantones(response.cantons);
      setLoadingData(false);
    });

    setReloadCantones(false);
  }, [token, reloadCantones]);

  const columns = [
    {
      title: "Nombre",
      width: 80,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Provincia",
      width: 100,
      dataIndex: ["provincia", "name"],
      key: "provinciaId",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editCanton(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteCanton(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addCanton = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo canton");
    setDisabled(true);
    setmodalContent(
      <EditCantonForm
        canton={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCantones={setReloadCantones}
        buttonName="Agregar canton"
        addSlag={"add"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  const editCanton = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar canton");
    setDisabled(false);
    setmodalContent(
      <EditCantonForm
        canton={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCantones={setReloadCantones}
        buttonName="Editar canton"
        addSlag={"edit"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };

  const deleteCanton = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar canton");
    setDisabled(true);
    setmodalContent(
      <EditCantonForm
        canton={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCantones={setReloadCantones}
        buttonName="Eliminar canton"
        addSlag={"delete"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  return (
    <div className="cantones">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Ubicación</Breadcrumb.Item>
        <Breadcrumb.Item>Canton</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addCanton}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={cantones}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen cantones"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
