import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateCausasApi,
  AddCausaApi,
  DeleteCausaApi,
} from "../../../../api/causa.api";

import "./EditCausaForm.scss";

export default function EditCausaForm(props) {
  const { causa, setIsVisibleModal, setReloadCausas, buttonName, addSlag } =
    props;
  const [causaData, setCausaData] = useState({});

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    setCausaData({
      description: "",
      name: "",
      icon: "",
    });
  };

  useEffect(() => {
    if (causa) {
      setCausaData({
        id: causa.id,
        description: causa.description,
        name: causa.name,
      });
    } else {
      setCausaData({
        id: null,
        description: "",
        name: "",
      });
    }
  }, [causa]);

  const addUpdateCausa = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();
    let causaUpdate = causaData;
    if (!causaUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddCausaApi(token, causaUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCausas(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateCausasApi(token, causaUpdate, causa.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCausas(true);
        setIsVisibleModal(false);
        setCausaData({
          ...causaData,
        });
      });
    } else {
      DeleteCausaApi(token, causa.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadCausas(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-ambito-form">
      <form className="form-edit" onSubmit={addUpdateCausa}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={causaData.name}
                onChange={(e) =>
                  setCausaData({
                    ...causaData,
                    name: e.target.value,
                  })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
