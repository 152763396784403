import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import { Table, Button, Empty, Breadcrumb, Affix } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Ciclo.scss";
import { getCiclosApi } from "../../../api/ciclo.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditCicloForm from "../../../components/Admin/Ciclo/EditCicloForm";

export default function Ciclo() {
  const [ciclo, setCiclo] = useState([]);
  const token = getAccessTokenApi();
  const [reloadCiclos, setReloadCiclos] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getCiclosApi(token, true).then((response) => {
      setCiclo(response.ciclos);
      setLoadingData(false);
    });

    setReloadCiclos(false);
  }, [token, reloadCiclos]);

  const addCiclo = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo ciclo productivo del cuy");
    setmodalContent(
      <EditCicloForm
        ciclo={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCiclos={setReloadCiclos}
        buttonName="Agregar ciclo"
        addSlag={"add"}
      />
    );
  };

  const editCiclo = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar ciclo productivo del cuy");
    setmodalContent(
      <EditCicloForm
        ciclo={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCiclos={setReloadCiclos}
        buttonName="Editar ciclo productivo del cuy"
        addSlag={"edit"}
      />
    );
  };

  const deleteCiclo = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar ciclo productivo del cuy");
    setmodalContent(
      <EditCicloForm
        ciclo={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCiclos={setReloadCiclos}
        buttonName="Eliminar ciclo productivo del cuy"
        addSlag={"delete"}
      />
    );
  };

  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editCiclo(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteCiclo(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="ciclo">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Alertas</Breadcrumb.Item>
        <Breadcrumb.Item>Ciclos productivos del cuy</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addCiclo}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={ciclo}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen ciclos productivo del cuy"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
