import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Tipo.scss";

import ModalUser from "../../../components/Admin/ModalUser";
import EditTipoForm from "../../../components/Admin/Tipo/EditTipoForm";
import { getAllTiposApi } from "../../../api/tipo.api";

export default function Tipo() {
  const [tipos, setTipos] = useState([]);
  const token = getAccessTokenApi();
  const [reloadTipos, setReloadTipos] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getAllTiposApi(token).then((response) => {
      setTipos(response.tipos);
      setLoadingData(false);
    });

    setReloadTipos(false);
  }, [token, reloadTipos]);

  const columns = [
    {
      title: "Nombre",
      width: 120,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Ciclo",
      width: 80,
      dataIndex: ["ciclo", "name"],
      key: "cicloId",
    },
    {
      title: "Descripción",
      width: 120,
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Observación",
      width: 120,
      dataIndex: "observation",
      key: "observation",
    },
    {
      title: "Mínimo (días)",
      width: 40,
      dataIndex: "min",
      key: "min",
    },
    {
      title: "Máximo (días)",
      width: 40,
      dataIndex: "max",
      key: "max",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editTipo(record)}
          >
            <EditOutlined />
          </Button>
          <Button size="small" type="danger" onClick={() => deleteTipo(record)}>
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addTipo = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo tipo");
    setDisabled(true);
    setmodalContent(
      <EditTipoForm
        tipo={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTipos={setReloadTipos}
        buttonName="Agregar tipo de alerta"
        addSlag={"add"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  const editTipo = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar tipo de alerta");
    setDisabled(false);
    setmodalContent(
      <EditTipoForm
        tipo={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTipos={setReloadTipos}
        buttonName="Editar tipo de alerta"
        addSlag={"edit"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };

  const deleteTipo = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar tipo de alerta");
    setDisabled(true);
    setmodalContent(
      <EditTipoForm
        tipo={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadTipos={setReloadTipos}
        buttonName="Eliminar tipo de alerta"
        addSlag={"delete"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  return (
    <div className="tipos">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Ubicación</Breadcrumb.Item>
        <Breadcrumb.Item>Tipo</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addTipo}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={tipos}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen tipos de alertas"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
