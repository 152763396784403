import React, { useState, useEffect } from "react";
import { Table, Button, Affix, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../../api/auth";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Registro.scss";

import ModalUser from "../../../../components/Admin/ModalUser";
import EditRegistroForm from "../../../../components/Admin/Registro/EditRegistroForm";
import { getAllRegistrosApi } from "../../../../api/registro.api";
import { formatDateHour } from "../../../../utils/functions";
import moment from "moment";

export default function Registro() {
  const [registros, setRegistros] = useState([]);
  const token = getAccessTokenApi();
  const [reloadRegistros, setReloadRegistros] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    getAllRegistrosApi(token).then((response) => {
      console.log(response);
      setRegistros(response.registros);
      setLoadingData(false);
    });

    setReloadRegistros(false);
  }, [token, reloadRegistros]);

  const columns = [
    {
      title: "Título",
      width: 80,
      dataIndex: "title",
      key: "title",
      fixed: "left",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Tipo",
      width: 100,
      dataIndex: ["tipo", "name"],
      key: "tipoId",
    },
    {
      title: "Fecha actualización",
      width: 100,
      dataIndex: ["updatedAt"],
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortDirections: ["descend", "ascend"],
      render: (fecha) => {
        return formatDateHour(fecha);
      },
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editRegistro(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteRegistro(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  const addRegistro = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo registro");
    setDisabled(true);
    setmodalContent(
      <EditRegistroForm
        registro={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadRegistros={setReloadRegistros}
        buttonName="Agregar registro"
        addSlag={"add"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  const editRegistro = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar registro");
    setDisabled(false);
    setmodalContent(
      <EditRegistroForm
        registro={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadRegistros={setReloadRegistros}
        buttonName="Editar registro"
        addSlag={"edit"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };

  const deleteRegistro = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar registro");
    setDisabled(true);
    setmodalContent(
      <EditRegistroForm
        registro={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadRegistros={setReloadRegistros}
        buttonName="Eliminar registro"
        addSlag={"delete"}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  };
  return (
    <div className="registros">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Consultas</Breadcrumb.Item>
        <Breadcrumb.Item>Registros de información</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addRegistro}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={registros}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen registros"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
