import React, { useState, useEffect } from "react";
import { TreeSelect } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getProvinciasApi } from "../../../../api/provincia.api";

import "./SelectCantonForm.scss";

const { TreeNode } = TreeSelect;

export default function SelectCantonForm(props) {
  const { parroquiaData, setParroquiaData, addSlag } = props;
  const [provinciaData, setProvinciaData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [cantonId, setCantonId] = useState();

  useEffect(() => {
    getProvinciasApi(token).then((response) => {
      setProvinciaData(response.provincias);
      console.log(parroquiaData);
      if (parroquiaData) {
        setCantonId(parroquiaData.cantonId);
      }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, parroquiaData]);

  const handleAtributoChange = (value) => {
    setCantonId(value);
    setParroquiaData({
      ...parroquiaData,
      cantonId: value,
    });
  };

  return (
    <div className="select-provincia-form">
      {/* <Select
        placeholder="Seleccione la provincia"
        value={cantonId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {provinciaData && provinciaData.map
          ? provinciaData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          : ""}
      </Select> */}
      <TreeSelect
        showSearch
        style={{ width: "100%" }}
        value={cantonId}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Seleccione el canton"
        allowClear
        treeDefaultExpandAll
        onChange={handleAtributoChange}
      >
        {provinciaData && provinciaData.map
          ? provinciaData.map((item) => (
              <TreeNode
                key={"provincia_" + item.id}
                value={"provincia_" + item.id}
                title={item.name}
                selectable={false}
              >
                {item.cantones && item.cantones.map
                  ? item.cantones.map((canton) => (
                      <TreeNode
                        key={canton.id}
                        value={canton.id}
                        title={canton.name}
                      ></TreeNode>
                    ))
                  : ""}
              </TreeNode>
            ))
          : ""}
        {/* <TreeNode value="parent 1" title="parent 1">
        <TreeNode value="parent 1-0" title="parent 1-0">
          <TreeNode value="leaf1" title="leaf1" />
          <TreeNode value="leaf2" title="leaf2" />
        </TreeNode>
        <TreeNode value="parent 1-1" title="parent 1-1">
          <TreeNode value="leaf3" title={<b style={{ color: '#08c' }}>leaf3</b>} />
        </TreeNode>
      </TreeNode> */}
      </TreeSelect>
    </div>
  );
}
