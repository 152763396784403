import React, { useState, useEffect } from "react";
import { Table, Empty, Breadcrumb } from "antd";
import { getAccessTokenApi } from "../../../../api/auth";

import "./Muertes.scss";

import { getAllMuertesApi } from "../../../../api/consultas.api";
import { formatDate } from "../../../../utils/functions";

export default function Consulta() {
  const [consultas, setConsultas] = useState([]);
  const token = getAccessTokenApi();
  const [reloadConsultas, setReloadConsultas] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getAllMuertesApi(token).then((response) => {
      setConsultas(response.muertes);
      setLoadingData(false);
    });

    setReloadConsultas(false);
  }, [token, reloadConsultas]);

  const columns = [
    {
      title: "id",
      width: 80,
      dataIndex: "id",
      key: "id",
      fixed: "left",
    },
    {
      title: "Muertos",
      width: 80,
      dataIndex: "vivos",
      key: "vivos",
    },
    {
      title: "Causa",
      width: 150,
      dataIndex: "nameCausa",
      key: "nameCausa",
    },
    {
      title: "Cuyera",
      width: 100,
      dataIndex: "nameCuyera",
      key: "nameCuyera",
    },
    {
      title: "Usuario",
      width: 100,
      dataIndex: "nameUser",
      key: "nameUser",
    },
    {
      title: "Cedula",
      width: 100,
      dataIndex: "cedulaUser",
      key: "cedulaUser",
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (fecha) => {
        return formatDate(fecha);
      },
    },
  ];

  return (
    <div className="muertes">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Consultas</Breadcrumb.Item>
        <Breadcrumb.Item>Muertes</Breadcrumb.Item>
      </Breadcrumb>
      <Table
        columns={columns}
        dataSource={consultas}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen muertes registradas"
            />
          ),
        }}
      />
    </div>
  );
}
