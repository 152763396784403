import React, { useState, useEffect } from "react";
import { getAccessTokenApi } from "../../../api/auth";

import { Table, Button, Empty, Breadcrumb, Affix } from "antd";

import EditOutlined from "@ant-design/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/DeleteOutlined";

import "./Causa.scss";
import { getCausasApi } from "../../../api/causa.api";

import ModalUser from "../../../components/Admin/ModalUser";
import EditCausaForm from "../../../components/Admin/Causa/EditCausaForm";

export default function Causa() {
  const [causa, setCausa] = useState([]);
  const token = getAccessTokenApi();
  const [reloadCausas, setReloadCausas] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState();
  const [modalContent, setmodalContent] = useState();
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getCausasApi(token, true).then((response) => {
      setCausa(response.causas);
      setLoadingData(false);
    });

    setReloadCausas(false);
  }, [token, reloadCausas]);

  const addCausa = () => {
    setIsVisibleModal(true);
    setModalTitle("Agregar nuevo tipo de causa");
    setmodalContent(
      <EditCausaForm
        causa={null}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCausas={setReloadCausas}
        buttonName="Agregar tipo de causa"
        addSlag={"add"}
      />
    );
  };

  const editCausa = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Editar tipo de causa");
    setmodalContent(
      <EditCausaForm
        causa={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCausas={setReloadCausas}
        buttonName="Editar tipo de causa"
        addSlag={"edit"}
      />
    );
  };

  const deleteCausa = (record) => {
    setIsVisibleModal(true);
    setModalTitle("Eliminar tipo de causa");
    setmodalContent(
      <EditCausaForm
        causa={record}
        setIsVisibleModal={setIsVisibleModal}
        setReloadCausas={setReloadCausas}
        buttonName="Eliminar tipo de causa"
        addSlag={"delete"}
      />
    );
  };

  const columns = [
    {
      title: "Nombre",
      width: 150,
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <div>
          <Button
            size="small"
            type="primary"
            className="margin-right-minimun"
            onClick={() => editCausa(record)}
          >
            <EditOutlined />
          </Button>
          <Button
            size="small"
            type="danger"
            onClick={() => deleteCausa(record)}
          >
            <DeleteOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="causa">
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Administrador</Breadcrumb.Item>
        <Breadcrumb.Item>Tipos de causa y desinfección</Breadcrumb.Item>
      </Breadcrumb>
      <Affix offsetTop={80}>
        <Button type="primary" onClick={addCausa}>
          Agregar
        </Button>
      </Affix>
      <br />
      <Table
        columns={columns}
        dataSource={causa}
        loading={loadingData}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No existen causas de muerte"
            />
          ),
        }}
      />
      <ModalUser
        title={modalTitle}
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        width={500}
      >
        {modalContent}
      </ModalUser>
    </div>
  );
}
