import React, { useState, useEffect } from "react";
import { TreeSelect } from "antd";

import { getAccessTokenApi } from "../../../../api/auth";
import { getProvinciasApi } from "../../../../api/provincia.api";

import "./SelectParroquiaForm.scss";

const { TreeNode } = TreeSelect;

export default function SelectParroquiaForm(props) {
  const { comunidadData, setComunidadData, addSlag } = props;
  const [provinciaData, setProvinciaData] = useState({});
  const token = getAccessTokenApi();
  const [loadingData, setLoadingData] = useState(true);
  const [parroquiaId, setParroquiaId] = useState();

  useEffect(() => {
    getProvinciasApi(token).then((response) => {
      setProvinciaData(response.provincias);
      setParroquiaId(comunidadData.parroquiaId);
      //   if (addSlag === "add") {
      //     setProvinciaId(null);
      //   } else {
      //     setProvinciaId(atributoData.provinciaId);
      //   }
    });
    setLoadingData(false);
  }, [token, loadingData, addSlag, comunidadData]);

  const handleAtributoChange = (value) => {
    setParroquiaId(value);
    setComunidadData({
      ...comunidadData,
      parroquiaId: value,
    });
  };

  return (
    <div className="select-provincia-form">
      {/* <Select
        placeholder="Seleccione la provincia"
        value={parroquiaId}
        onChange={handleAtributoChange}
        disabled={disabled}
      >
        {provinciaData && provinciaData.map
          ? provinciaData.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))
          : ""}
      </Select> */}
      <TreeSelect
        showSearch
        style={{ width: "100%" }}
        value={parroquiaId}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder="Seleccione la parroquia"
        allowClear
        treeDefaultExpandAll
        onChange={handleAtributoChange}
      >
        {provinciaData && provinciaData.map
          ? provinciaData.map((item) => (
              <TreeNode
                key={"provincia_" + item.id}
                value={"provincia_" + item.id}
                title={item.name}
                selectable={false}
                treeDefaultExpandAll={true}
              >
                {item.cantones && item.cantones.map
                  ? item.cantones.map((canton) => (
                      <TreeNode
                        key={"canton_" + canton.id}
                        value={"canton_" + canton.id}
                        title={canton.name}
                        selectable={false}
                      >
                        {canton.parroquias && canton.parroquias.map
                          ? canton.parroquias.map((parroquia) => (
                              <TreeNode
                                key={parroquia.id}
                                value={parroquia.id}
                                title={parroquia.name}
                              ></TreeNode>
                            ))
                          : ""}
                      </TreeNode>
                    ))
                  : ""}
              </TreeNode>
            ))
          : ""}
        {/* <TreeNode value="parent 1" title="parent 1">
        <TreeNode value="parent 1-0" title="parent 1-0">
          <TreeNode value="leaf1" title="leaf1" />
          <TreeNode value="leaf2" title="leaf2" />
        </TreeNode>
        <TreeNode value="parent 1-1" title="parent 1-1">
          <TreeNode value="leaf3" title={<b style={{ color: '#08c' }}>leaf3</b>} />
        </TreeNode>
      </TreeNode> */}
      </TreeSelect>
    </div>
  );
}
