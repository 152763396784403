import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import SelectParroquiaForm from "../../Parroquia/SelectParroquiaForm";
import { getAccessTokenApi } from "../../../../api/auth";
import {
  UpdateComunidadApi,
  AddComunidadApi,
  DeleteComunidadApi,
} from "../../../../api/comunidad.api";

import "./EditComunidadForm.scss";

export default function EditComunidadForm(props) {
  const {
    comunidad,
    setIsVisibleModal,
    setReloadComunidads,
    buttonName,
    addSlag,
  } = props;
  const [comunidadData, setComunidadData] = useState({});
  const [loadingData, setLoadingData] = useState(false);

  const resetForm = () => {
    const inputs = document.getElementsByTagName("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.remove("success");
      inputs[i].classList.remove("error");
    }

    //setValue("");

    setComunidadData({
      name: "",
      parroquiaId: null,
    });
  };

  useEffect(() => {
    if (comunidad) {
      setComunidadData({
        id: comunidad.id,
        name: comunidad.name,
        parroquiaId: comunidad.parroquiaId,
        urbana: comunidad.urbana,
      });
      //setValue(comunidad.description);
    } else {
      //setValue("");
      setComunidadData({
        id: null,
        name: "",
        parroquiaId: null,
        urbana: null,
      });
    }
  }, [comunidad]);

  const addUpdateComunidad = (e) => {
    e.preventDefault();
    const token = getAccessTokenApi();

    let comunidadUpdate = comunidadData;
    //comunidadUpdate.description = value;
    if (!comunidadUpdate.parroquiaId && addSlag !== "delete") {
      notification["error"]({
        message: "El canton es obligatorio",
      });
      return;
    }

    if (!comunidadUpdate.name) {
      notification["error"]({
        message: "El nombre es obligatorio",
      });
      return;
    }

    if (addSlag === "add") {
      AddComunidadApi(token, comunidadUpdate).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadComunidads(true);
        setIsVisibleModal(false);
        resetForm();
      });
    } else if (addSlag === "edit") {
      UpdateComunidadApi(token, comunidadUpdate, comunidad.id).then(
        (result) => {
          notification["success"]({
            message: result.message,
          });
          setReloadComunidads(true);
          setIsVisibleModal(false);
          // setComunidadData({
          //   ...comunidadData,
          // });
        }
      );
    } else {
      DeleteComunidadApi(token, comunidad.id).then((result) => {
        notification["success"]({
          message: result.message,
        });
        setReloadComunidads(true);
        setIsVisibleModal(false);
      });
    }
  };

  return (
    <div className="edit-comunidad-form">
      <form className="form-edit" onSubmit={addUpdateComunidad}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <SelectParroquiaForm
                setComunidadData={setComunidadData}
                comunidadData={comunidadData}
                addSlag={addSlag}
                loadingData={loadingData}
                setLoadingData={setLoadingData}
                disabled={addSlag === "delete" ? true : false}
              ></SelectParroquiaForm>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input
                placeholder="Nombre"
                value={comunidadData.name}
                onChange={(e) =>
                  setComunidadData({ ...comunidadData, name: e.target.value })
                }
                disabled={addSlag === "delete" ? true : false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            type={addSlag === "delete" ? "danger" : "primary"}
            htmlType="submit"
            className="btn-submit"
          >
            {buttonName}
          </Button>
        </Form.Item>
      </form>
    </div>
  );
}
